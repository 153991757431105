const Apps: any = {
    "e6227fce-4f3d-4a98-beaa-5960d9c16686": {
        name: "Agoda",
        endpoint: "https://certifiedcode.wixsite.com/reviews-agoda",
        urls: {
            profile: "https://support.certifiedcode.us/en/articles/8935072-how-to-find-agoda-profile-url"
        },
        placeholders: {
            url: "https://www.agoda.com/shelburne-hotel-suites-by-affinia/hotel/new-york-ny-us.html"
        }
    },
    "7e742a29-f981-4686-8ae2-01e53345f40a": {
        name: "Business Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-avvo",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Avvo / Angie’s List / BBB / Yelp / TrustRadius"
        }
    },
    "dff4144c-caaa-43ca-9862-86bc4be19668": {
        name: "Travel Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-expedia",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: ExExpedia (Only hotel) / Booking.com / Hotels.com / Kayak / Priceline"
        }
    },
    "95ca4c94-ebcc-4303-aa55-ea474aabba86": {
        name: "Facebook",
        endpoint: "https://certifiedcode.wixsite.com/reviews-facebook",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.facebook.com/Hiltonhotelsuk"
        }
    },
    "b9425426-51b8-45ac-90e6-7d557bcc65a3": {
        name: "Google",
        endpoint: "https://certifiedcode.wixsite.com/reviews-google",
        urls: {
            profile: null
        },
        placeholders: {
            url: "e.g. Starbucks, 1499 Washington Ave, San Leandro"
        }
    },
    "95e60faa-228f-4749-b26f-bf1c6537bf54": {
        name: "App Market Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-playstore",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Google Playstore / Apple Appstore / AlternativeTo / G2Crowd / IT Central Station (rebranded as Peerspot)"
        }
    },
    "961d798c-422f-464d-aeb9-cda26cbab812": {
        name: "E-commerce Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-gshopping",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Google Shopping / Bestbuy (US/CA) / Bol / Flipkart / Walmart"
        }
    },
    "75d4019a-8da1-4de9-9350-3ceaf149c093": {
        name: "TripAdvisor",
        endpoint: "https://certifiedcode.wixsite.com/reviews-tripadvisor",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.tripadvisor.com/Hotel_Review-g187147-d197666-Reviews-Hotel_Ares_Paris-Paris_Ile_de_France.html"
        }
    },
    "59f64b82-8c42-4ad7-93a0-e85a13bf584c": {
        name: "Yelp",
        endpoint: "https://certifiedcode.wixsite.com/reviews-yelp",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.yelp.com/biz/naturally-delicious-brooklyn"
        }
    },
    "07978501-1717-4cad-a1ca-f0a9a1853d47": {
        name: "Real Estate",
        endpoint: "https://certifiedcode.wixsite.com/reviews-zillow",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Zillow / Apartments.com / ApartmentRatings / HomeAdvisor / HomeAway (now Vrbo)"
        }
    },
    "0424b5dc-251c-4e09-b957-ba1cb1ac542e": {
        name: "Restaurant Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-zomato",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Zomato / Deliveroo / DoorDash / OpenTable / Ubereats",
            example: "https://www.zomato.com/austin/bangers-sausage-house-beer-garden-downtown\nhttps://deliveroo.ae/menu/sharjah/al-majaz/falafil-fareyha-cafeteria\nhttps://www.doordash.com/store/charlie's-chicken-wings-new-york-1591153/\nhttps://www.opentable.com/gaucho-piccadilly\nhttps://www.ubereats.com/store/subway-162-4th-avenue-north/muRDJN9JR4WcAWRhaUeCsQ"
        }
    },
    "51b215a8-1ecf-4c4b-b020-cf0e864fa4a6": {
        name: "Airbnb",
        endpoint: "https://certifiedcode.wixsite.com/reviews-airbnb",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.airbnb.com/rooms/5748150"
        }
    },
    "2b80142d-59ff-4feb-ac3a-eab9b23f38eb": {
        name: "AliExpress",
        endpoint: "https://certifiedcode.wixsite.com/reviews-aliexpress",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.aliexpress.com/item/32804769666.html"
        }
    },
    "4b4686e2-91bf-47a8-9e84-2dafa7317da7": {
        name: "AlternativeTo",
        endpoint: "https://certifiedcode.wixsite.com/reviews-ato",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.alternativeto.com/"
        }
    },
    "8f6f913d-8d3b-494e-9115-88be873dd086": {
        name: "Amazon",
        endpoint: "https://certifiedcode.wixsite.com/reviews-amazon",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.amazon.com/Razer-BlackShark-V2-Gaming-Headset/dp/B09PZG4R17"
        }
    },
    "5b7d9eed-d5da-41cb-b1b9-504ea19f599f": {
        name: "Angie's List",
        endpoint: "https://certifiedcode.wixsite.com/reviews-angies-list",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.angi.com/"
        }
    },
    "fe764f95-3125-431e-b710-0ddf14e157c6": {
        name: "ApartmentRatings",
        endpoint: "https://certifiedcode.wixsite.com/reviews-apartmentrat",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.apartmentratings.com/"
        }
    },
    "320ff8c8-7282-482b-acff-a90cdb4c2f76": {
        name: "Apartments.com",
        endpoint: "https://certifiedcode.wixsite.com/reviews-apartmentsco",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.apartments.com/"
        }
    },
    "af9bcd88-5c5e-44af-bf92-d821ec19f048": {
        name: "Apple App Store",
        endpoint: "https://certifiedcode.wixsite.com/reviews-app-store",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://apps.apple.com/"
        }
    },
    "8d1afc55-4e59-432c-a28e-4fc4cebf2307": {
        name: "Baidu",
        endpoint: "https://certifiedcode.wixsite.com/reviews-baidu",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.baidu.com/"
        }
    },
    "4724e59a-0682-4b56-ad4d-92618e3dde0b": {
        name: "BBB",
        endpoint: "https://certifiedcode.wixsite.com/reviews-bbb",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.bbb.com/"
        }
    },
    "6666a51c-2346-46b0-b523-2cb8610e2fd2": {
        name: "Bestbuy",
        endpoint: "https://certifiedcode.wixsite.com/reviews-bestbuy",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.bestbuy.com/"
        }
    },
    "396cf68c-0885-43c7-bdcf-bcb39f8f977d": {
        name: "Bilbayt",
        endpoint: "https://certifiedcode.wixsite.com/reviews-bilbayt",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.bilbayt.com/"
        }
    },
    "3adbd452-af9d-47d9-9094-33d2ee4c698d": {
        name: "Bol",
        endpoint: "https://certifiedcode.wixsite.com/reviews-bol",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.bol.com/"
        }
    },
    "a4326bed-e887-4516-a413-5bbeffbde4df": {
        name: "Booking.com",
        endpoint: "https://certifiedcode.wixsite.com/reviews-booking-com",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.booking.com/"
        }
    },
    "731ce3d3-b309-4ebc-a5fc-d455912ab6cc": {
        name: "Capterra",
        endpoint: "https://certifiedcode.wixsite.com/reviews-capterra",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.capterra.com/"
        }
    },
    "126f766d-ec66-4da0-bdbb-7cb2f939bb5f": {
        name: "Automotive Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-cargurus",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: CarGurus / Cars.com / Carvana / Edmunds / DealerRater"
        }
    },
    "5398eb31-6771-4c7a-bdea-5db7c03d725a": {
        name: "Cars.com",
        endpoint: "https://certifiedcode.wixsite.com/reviews-cars-com",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.cars.com/"
        }
    },
    "287093b6-5886-4599-a40b-b3ced8753afe": {
        name: "Carvana",
        endpoint: "https://certifiedcode.wixsite.com/reviews-carvana",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.carvana.com/"
        }
    },
    "dc17be5b-6ad4-467f-a417-7712e8cdc8e3": {
        name: "Citysearch",
        endpoint: "https://certifiedcode.wixsite.com/reviews-citysearch",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.citysearch.com/"
        }
    },
    "c9a506a1-0a9b-48e2-abd4-52aeb1fa8549": {
        name: "Job Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-classpass",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Glassdoor / Indeed / Greatschools / Classpass / The Knot"
        }
    },
    "4e7a7c66-9e1b-40fc-b58c-ea99cfaf0fc9": {
        name: "Consumer Ratings",
        endpoint: "https://certifiedcode.wixsite.com/reviews-consumer-aff",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: Consumer Affairs / ResellerRatings / Reviews.io / Sitejabber / Trustpilot"
        }
    },
    "a622d646-9917-4dc6-b3d9-cd57f957c6b5": {
        name: "Credit Karma",
        endpoint: "https://certifiedcode.wixsite.com/reviews-credit-karma",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.creditkarma.com/"
        }
    },
    "41d8bc60-9dc5-4ace-84e5-6d5f0f74d2a5": {
        name: "Customer Lobby",
        endpoint: "https://certifiedcode.wixsite.com/reviews-customerlobb",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.customerlobby.com/"
        }
    },
    "505edc05-27b9-49e6-80f3-68e3134ac792": {
        name: "DealerRater",
        endpoint: "https://certifiedcode.wixsite.com/reviews-dealerrater",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.dealerrater.com/"
        }
    },
    "5757305d-765e-463c-ab41-2e2335898bc6": {
        name: "Deliveroo",
        endpoint: "https://certifiedcode.wixsite.com/reviews-deliveroo",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.deliveroo.com/"
        }
    },
    "05eb7eb8-1cd3-4c3e-b3c0-ead1ccfe3380": {
        name: "Drizly",
        endpoint: "https://certifiedcode.wixsite.com/reviews-drizly",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.drizly.com/"
        }
    },
    "65c4238c-6789-4217-81ab-2b898b8cb3fd": {
        name: "eBay",
        endpoint: "https://certifiedcode.wixsite.com/reviews-ebay",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://feedback.ebay.com/ws/eBayISAPI.dll?ViewFeedback2&ftab=FeedbackAsSeller&userid=lilyann76"
        }
    },
    "33baab7c-df3c-43d0-891b-dcc7bf269acb": {
        name: "Edmunds",
        endpoint: "https://certifiedcode.wixsite.com/reviews-edmunds",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.edmunds.com/"
        }
    },
    "ba7d02aa-b677-46cb-8702-b104f3d42bd2": {
        name: "Etsy",
        endpoint: "https://certifiedcode.wixsite.com/reviews-etsy",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.etsy.com/shop/bjumuk/"
        }
    },
    "a23c1ced-80b9-41ce-8aac-c5c65d8f7298": {
        name: "Law and Legal Sites",
        endpoint: "https://certifiedcode.wixsite.com/reviews-findlaw",
        urls: {
            profile: null
        },
        placeholders: {
            url: "Supports: FindLaw / Lawyers.com / Martindale / Niche / Citysearch"
        
        }
    },
    "2644c2f8-32dc-4700-8f74-a9993c3ef9e9": {
        name: "Flipkart",
        endpoint: "https://certifiedcode.wixsite.com/reviews-flipkart",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.flipkart.com/"
        }
    },
    "42e366b6-e68e-45db-942c-74fbc33cf49c": {
        name: "Foursquare",
        endpoint: "https://certifiedcode.wixsite.com/reviews-foursquare",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.foursquare.com/"
        }
    },
    "ab905f78-148f-4c30-9189-cc740157d4c5": {
        name: "Gartner",
        endpoint: "https://certifiedcode.wixsite.com/reviews-gartner",
        urls: {
            profile: null
        },
        placeholders: {
            url: "https://www.gartner.com/"
        }
    },
}

export {
    Apps
}